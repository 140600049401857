// Predefined breakpoints sizes
$XSmall : 599.98px;
$Small  : 959.98px;
$Medium : 1279.98px;
$Large  : 1919.98px;
$XLarge : 1920px;

  // Header
  $header-height    : 3.265rem;
  // menu
  $menu-width-close : 3.5rem;
  $menu-width-open  : 12.6rem;
  // Body
    /*$body-width : calc(100vw - 3.5rem);*/
    $body-width : 100%;
    $body-height: calc(100vh - 3.265rem);
    // Body content
    $body-content-head-height-0: 7.5rem;
    $body-content-head-height-1: 3.5rem;
    // $body-height = 100Vh - ($header-height -$body-content-head-height)    ( No se puede usar la variable en calc() )
    $body-content-body-height-0: calc(100vh - 10.765rem);
    $body-content-body-height-1: calc(100vh - 6.765rem);
    $body-content-body-height-2: calc(100vh - 3.765rem);

// Font-size
  // default is 20px | Nos basamos los REM que tiene la pagina
  //importante!! no pasar estas variables en el scss
  $font-size-4k       : 30px;
  $font-size-2k       : 23px;
  $font-size-fullHD   : 20px;
  $font-size-default  : 14px;
  $font-size-800      : 10px;

  // Tamanio de fuentes usadas
  //estas variables se pueden pasar en el scss
  $font-size-12         : 0.6rem; // 12px
  $font-size-14         : 0.7rem; // 14px
  $font-size-primary    : 0.8rem; // 16p
  $font-size-head       : 1.7rem; // 34px
  $font-size-head-span  : 1rem;   // 20px
  $font-size-24         : 1.2rem; // 24px
  $font-size-26         : 1.3rem; // 26px
  $font-size-28         : 1.4rem; // 28px
  $font-size-30         : 1.5rem; // 30px
  $font-size-32         : 1.6rem; // 32px
  $font-size-34         : 1.7rem; // 34px
  $font-size-42         : 2.1rem; // 42px
  // button
    $font-size-button       : 0.7rem;
  // Input
    $font-size-input-label  : 0.8rem;

// Colors
$color-primary    : #19191d;
$color-secondary  : #787885;
$color-third      : #5a5b6a;
$color-quarter    : #fff;
$color-icon       : #b4b4bb;
$color-placeholder: #9696a0;

/* Button disabled*/
  $color-button-disabled: #f9f9f9;
  $color-icon-disabled  : #00000029;

/* Dark */
    $color-dark         : #292a31;
  /* Red */
    $color-red          : #f6655a;
  /* Blue */
    $color-blue         : #4f91ff;
  /* Pink */
    $color-pink         : #ef6292;
  /* Purple */
    $color-purple       : #7985cb;
  /* Green */
    $color-green        : #33a9a9;
  /* Orange */
    $color-orange       : #fb982e;
    $color-orange-light : #fddcb9;

// Shadow
$box-shadow: 0 0.1rem 0.2rem 0 #d2d2d6, 0 0.1rem 0.2rem 0 #b4b4bb !important;

// Colors Border
$color-border-primary   : #d2d2d6;
$color-border-secondary : #787885;

// Border
$border-size-primary: 0.05rem;
$border-size-error  : 0.1rem;
$border             : solid $border-size-primary $color-border-primary;
$border-radius      : 0.35rem;
$border-size-tabs   : 0.2rem;

// margin
  // 20px
  $margin       : 1rem;
  $margin-top   : 1rem;
  $margin-left  : 1rem;
  $margin-right : 1rem;
  $margin-bottom: 1rem;

  $margin-div       : calc(#{$margin} / 2);
  $margin-div-top   : calc(#{$margin-top} / 2);
  $margin-div-left  : calc(#{$margin-left} / 2);
  $margin-div-right : calc(#{$margin-right} / 2);
  $margin-div-bottom: calc(#{$margin-bottom} / 2);

  $margin-div-4       : calc(#{$margin} / 4);
  $margin-div-4-top   : calc(#{$margin-top} / 4);
  $margin-div-4-left  : calc(#{$margin-left} / 4);
  $margin-div-4-right : calc(#{$margin-right} / 4);
  $margin-div-4-bottom: calc(#{$margin-bottom} / 4);

  $margin-head       : 2rem;
  $margin-head-top   : 2rem;
  $margin-head-left  : 2rem;
  $margin-head-right : 2rem;
  $margin-head-bottom: 2rem;

// padding
  // 20px
  $padding       : 1rem;
  $padding-top   : 1rem;
  $padding-left  : 1rem;
  $padding-right : 1rem;
  $padding-bottom: 1rem;

  // Doble padding
  $padding-double       : $padding         + $padding;
  $padding-top-double   : $padding-top     + $padding-top;
  $padding-left-double  : $padding-left    + $padding-left;
  $padding-right-double : $padding-right   + $padding-right;
  $padding-bottom-double: $padding-bottom  + $padding-bottom;

  // Dividir padding
  $padding-div-1_5       : calc($padding / 1.5);
  $padding-div-1_5-top   : calc($padding-top / 1.5);
  $padding-div-1_5-left  : calc($padding-left / 1.5);
  $padding-div-1_5-right : calc($padding-right / 1.5);
  $padding-div-1_5-bottom: calc($padding-bottom / 1.5);

  $padding-div       : calc($padding / 2);
  $padding-div-top   : calc($padding-top / 2);
  $padding-div-left  : calc($padding-left / 2);
  $padding-div-right : calc($padding-right / 2);
  $padding-div-bottom: calc($padding-bottom / 2);

  $padding-div-5       : calc($padding / 5);
  $padding-div-5-top   : calc($padding-top / 5);
  $padding-div-5-left  : calc($padding-left / 5);
  $padding-div-5-right : calc($padding-right / 5);
  $padding-div-5-bottom: calc($padding-bottom / 5);

// side-nav.component
  $button-open-close-size    : 1.4rem;
  $button-open-close-size-div: calc(($button-open-close-size - ($border-size-primary * 2)) / 2);

// Buton
  $margin-button-icon             : 0.5rem;
  $margin-button-icon-top         : 0.5rem;
  $margin-button-icon-left        : 0.5rem;
  $margin-button-icon-right       : 0.5rem;
  $margin-button-icon-bottom      : 0.5rem;
  /* Primary */
    $background-button-primary        : #fff;
    $background-button-hover          : #f0f0f1;
    $background-button-hover-rgba     : rgba(240, 240, 241, 0);
    $background-button-hover-view-rgba: rgba(240, 240, 241, 1);
    $border-button                    : solid $border-size-primary $color-border-primary ;
    $border-button-radius             : 0.2rem;
  /* Dark */
    $background-button-dark         : #292a31;
    $background-button-dark-hover   : #3a3a44;
    $background-button-dark-hover2  : #f0f0f1;
    $border-button-dark             : solid $border-size-primary $color-border-secondary;
  /* Red */
    $background-button-red          : $color-red;
    $background-button-red-hover    : #f8877f;
    $background-button-red-hover2   : #feedec;
    $border-button-red              : solid $border-size-primary $background-button-red;
  /* Blue */
    $background-button-blue         : $color-blue;
    $background-button-blue-hover   : #76a9ff;
    $background-button-blue-hover2  : #ebf2ff;
    $border-button-blue             : solid $border-size-primary $background-button-blue;
  /* Pink */
    $background-button-pink         : $color-pink;
    $background-button-pink-hover   : #f285aa;
    $background-button-pink-hover2  : #fdedf2;
    $border-button-pink             : solid $border-size-primary $background-button-pink;
  /* Purple */
    $background-button-purple       : $color-purple;
    $background-button-purple-hover : #97a0d6;
    $background-button-purple-hover2: #f0f1f9;
    $border-button-purple           : solid $border-size-primary $background-button-purple;
  /* Green */
    $background-button-green        : $color-green;
    $background-button-green-hover  : #60bcbc;
    $background-button-green-hover2 : #e8f5f5;
    $border-button-green            : solid $border-size-primary $background-button-green;
  /* Orange */
    $background-button-orange       : $color-orange;
    $background-button-orange-hover : #fcaf5c;
    $background-button-orange-hover2: #fef3e7;
    $border-button-orange           : solid $border-size-primary $background-button-orange;
  /* Gray */
    $background-gray                : #f9f9fa;
/*
  Input checbok
*/
  $input-size-checkbox   : 0.8rem;
  $input-border-checkbox : 0.1rem;
  $input-radius-checkbox : 0.2rem;
/*
  FIN Input checbok
*/
