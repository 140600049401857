// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
/*@use 'sass:math' as math;*/

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$aon-material-primary: mat.define-palette(mat.$indigo-palette);
$aon-material-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$aon-material-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$aon-material-theme: mat.define-light-theme((
  color: (
    primary: $aon-material-primary,
    accent: $aon-material-accent,
    warn: $aon-material-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($aon-material-theme);
//
// Nuestras variables
//
@import 'src/variables.scss';

/*
  FONT
*/
@font-face {
  font-family: "Roboto";
  src: url(assets/fonts/Roboto/Roboto-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(assets/fonts/Roboto/Roboto-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(assets/fonts/Roboto/Roboto-Medium.ttf);
  font-display: swap;
}

/*
  Material SYMBOLS css
*/
@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/icons/material-symbols-outlined.woff2) format('woff2');
  font-display: swap;
}

body {
  --google-font-color-materialiconstwotone:none;
  --mdc-checkbox-state-layer-size: 1.3rem;
}
.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: $font-size-24;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
/*
  FIN Material SYMBOLS css
*/

/*
  General
*/
* {
  font-family   : "Roboto";
  color         : $color-primary;
  font-size     : $font-size-primary;
  caret-color   : $color-primary !important;
  font-weight   : 400;
  letter-spacing: .44px;
  line-height   : 1.2rem;
}

html {
  @media screen and (min-width: 3800px) {
    font-size: $font-size-4k !important;
/*    mat-toolbar #tool-bar-routes{
      background: yellow !important;
    }*/
  }
  @media screen and (min-width: 2501px) and (max-width: 3800px) {
    font-size: $font-size-2k !important;
/*    mat-toolbar #tool-bar-routes{
      background: green !important;
    }*/
  }
  @media screen and (min-width: 1600px) and (max-width: 2500px) {
    font-size: $font-size-fullHD !important;
/*    mat-toolbar #tool-bar-routes{
      background: blue !important;
    }*/
  }
  @media screen and (min-width: 801px) and (max-width: 1599px) {
    font-size: $font-size-default !important;
/*    mat-toolbar #tool-bar-routes{
      background: purple !important;
    }*/
  }
  @media screen and (max-width: 800px) {
    font-size: $font-size-800 !important;
/*    mat-toolbar #tool-bar-routes{
      background: orange !important;
    }*/

    .form{
      display: grid !important;
    }
  }

  body{
    margin: 0;
    overflow: hidden;
  }
}

.allSize {
  width : 100%;
  height: 100%;
}

/*
  width percentage
*/
  .width-10{
    width: 10%;
  }
  .width-20{
    width: 20%;
  }
  .width-30{
    width: 30%;
  }
  .width-40{
    width: 40%;
  }
  .width-50{
    width: 50%;
  }
  .width-60{
    width: 60%;
  }
  .width-70{
    width: 70%;
  }
  .width-80{
    width: 80%;
  }
  .width-90{
    width: 90%;
  }
  .width-100{
    width: 100%;
  }

/*
  Link
*/
  a,
  u {
    text-decoration: none;
  }

  a:visited,
  a:visited {
    color: $color-primary;
  }

/*
  Font size
*/
  .bold{
    font-family : 'Roboto-bold';
  }
/*
  Font uppercase
*/
  .uppercase{
    text-transform: uppercase !important;
  }

/*
  Grid aling in top
*/
  .griss mat-icon path{
    color: $color-secondary;
  }

  .left-content figure{
    justify-content: left !important;
  }

/*
  Color text
*/
  .dark{
    color: $color-dark;
  }
  .griss{
    color: $color-secondary;
  }
  .red{
    color: $color-red;
  }
  .blue{
    color: $color-blue;
  }
  .orange{
    color: $color-orange;
  }
  .green{
    color: $color-green;
  }
  .pink{
    color: $color-pink;
  }
  .purple{
    color: $color-purple;
  }

/*
  Text background
*/
  .background-text-griss,
  .background-text-griss-light,
  .background-text-red,
  .background-text-red-light,
  .background-text-blue,
  .background-text-orange,
  .background-text-orange-light,
  .background-text-green,
  .background-text-pink,
  .background-text-purple{
    padding         : 0.375rem 0.5rem;
    border-radius   : $border-radius;
  }
  .background-text-griss{
    background: $color-secondary;
  }
  .background-text-griss-light{
    background: #f0f0f1;
  }
  .background-text-red{
    background: $color-red;
  }
  .background-text-red-light{
    background: #fddcda;
  }
  .background-text-blue{
    background: $color-blue;
  }
  .background-text-orange{
    background: $color-orange;
  }
  .background-text-orange-light{
    background-color: #fddcb9;
  }
  .background-text-green{
    background: $color-green;
  }
  .background-text-pink{
    background: $color-pink;
  }
  .background-text-purple{
    background: $color-purple;
  }
/*
  Margin
*/
  .margin{
    margin: $margin;
  }
  .margin-top{
    margin-top: $margin-top;
  }
  .margin-left{
    margin-left: $margin-left;
  }
  .margin-right{
    margin-right: $margin-right;
  }
  .margin-bottom{
    margin-bottom: $margin-bottom;
  }
  .margin{
    margin: $margin;
  }
  .margin-top-2{
    margin-top: $margin-top * 2;
  }
  .margin-left-2{
    margin-left: $margin-left * 2;
  }
  .margin-right-2{
    margin-right: $margin-right * 2;
  }
  .margin-bottom-2{
    margin-bottom: $margin-bottom * 2;
  }
/*
  Padding
*/
  .padding{
    padding: $padding;
  }
  .padding-top{
    padding-top: $padding-top;
  }
  .padding-left{
    padding-left: $padding-left;
  }
  .padding-right{
    padding-right: $padding-right;
  }
  .padding-bottom{
    padding-bottom: $padding-bottom;
  }
  .padding{
    padding: $padding;
  }
  .padding-top-2{
    padding-top: $padding-top * 2;
  }
  .padding-left-2{
    padding-left: $padding-left * 2;
  }
  .padding-right-2{
    padding-right: $padding-right * 2;
  }
  .padding-bottom-2{
    padding-bottom: $padding-bottom * 2;
  }

/*
  Border
*/
  .border{
    border: $border;
  }
  .border-top{
    border-top: $border;
  }
  .border-left{
    border-left: $border;
  }
  .border-right{
    border-right: $border;
  }
  .border-bottom{
    border-bottom: $border;
  }
  .border-radius{
    border-radius: $border-radius;
  }

/*
  Form
*/
  .form-head{
    border-bottom : $border;
    font-family   : "Roboto-medium";
    font-size     : $font-size-head-span;
    padding-bottom: $margin;
    margin-bottom : $margin;
  }

  .form{
    display: flex;
    gap    : $margin;
    padding: 0 0 $padding 0;
    align-items: flex-start;

    app-input{
      flex: 2;
    }
  }
  .form:first-of-type{
    padding-top: $padding;
  }

  .form.no-top{
    padding-top: 0;
  }
  /*agregar input*/
  .form-add{
    display     : flex;
    gap         : $margin;

    .form-add-input{
      width: 100%;

      .form{
        padding-top : 0;
      }
    }

    .form-add-button{
      padding-top: $padding * 0.2;
    }
  }

/*
  Hr
*/
  hr{
    border    : $border;
    border-top: none;
    margin    : $margin;
  }

/*
  Cursor Pointer
*/
.pointer {
  cursor: pointer;
}

/*
  Texto en un circulo
*/
  .circle{
    margin-left     : $margin-div-left;
    width           : 1.7rem;
    height          : 1.7rem;
    border-radius   : 100rem;
    display         : inline-flex;
    align-items     : center;
    justify-content : center;
    color           : $color-primary;
  }

  /* Red */
    .red .circle,
    .circle.red{
      background-color: $background-button-red-hover2;
    }
  /* Blue */
    .blue .circle,
    .circle.blue{
     background-color: $background-button-blue-hover2;
    }
  /* Pink */
    .pink .circle,
    .circle.pink{
      background-color: $background-button-pink-hover2;
    }
  /* Purple */
    .purple .circle,
    .circle.purple{
      background-color: $background-button-purple-hover2;
    }
  /* Green */
    .green .circle,
    .circle.green{
      background-color: $background-button-green-hover2;
    }
  /* Orange */
    .orange .circle,
    .circle.orange{
      background-color: $background-button-orange-hover2;
    }
/*
  FIN Texto en un circulo
*/

/*
  Buttons
*/
  .button-no-style {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
/*
  FINButtons
*/

/*
  No elementos en una tabla
*/
.no-elements{
  display         : flex;
  justify-content : center;
  font-family     : "Roboto-Medium";
  padding         : $padding;
}
/*
  Fin No elementos en una tabla
*/
